<template>
    <div class="header-text">
        {{ headerText }}
    </div>
</template>

<script>
export default {
  name: 'AdminHeaderText',
  props: {
    headerText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  .header-text {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 50px;
    min-width: 460px;
  }
</style>
