<template>
    <div class="pagination-container">
        <span class="pagination-text">{{ paginationText }}</span>
        <arrow type="back" :disabled="backArrowDisabled" @arrow-click="back" />
        <arrow type="next" :disabled="nextArrowDisabled" @arrow-click="next" />
    </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    countStart: {
      type: [String, Number],
      required: true,
    },
    countEnd: {
      type: [String, Number],
      required: true,
    },
    countTotal: {
      type: [String, Number],
      required: true,
    },
    elementAlias: {
      type: String,
      required: true,
    },
  },
  computed: {
    backArrowDisabled() {
      return this.countStart === 1 || this.countStart === 0;
    },
    nextArrowDisabled() {
      return this.countEnd === this.countTotal;
    },
    paginationText() {
      return `${this.countStart} / ${this.countEnd} из ${this.countTotal} ${this.elementAlias}`;
    },
  },
  methods: {
    back() {
      if (!this.backArrowDisabled) {
        this.$emit('back');
      }
    },
    next() {
      if (!this.nextArrowDisabled) {
        this.$emit('next');
      }
    },
  },
};
</script>

<style scoped>
  .pagination-text {
    margin-right: 5px;
    cursor: default;
  }
  .pagination-container {
    display: contents;
  }
</style>
