import {mapGetters, mapMutations} from 'vuex';

/*
 * Data base access types
 */
const DATA_BASE_ACCESS_RW = 'readwrite';
const DATA_BASE_ACCESS_RO = 'readonly';

/*
 * Data bases
 */
const DATA_BASE_STRUCTURE = 'pwaStructure';
const DATA_BASE_USER = 'pwaUser';
const DATA_BASE_REQUEST = 'requests';

/*
 * Tables
 */
const TABLE_USER_DATA = 'userData';
const TABLE_BLOCK = 'blocks';
const TABLE_REQUEST_PEST = 'pest';
const TABLE_REQUEST_PROTECTION = 'protection';
// const TABLE_HOUSES = 'houses';

/*
 * Request types map
 */
const REQUEST_TYPE_MAP = {
  PEST_TYPE: TABLE_REQUEST_PEST,
  PROTECTION_TYPE: TABLE_REQUEST_PROTECTION,
};

export default {
  data() {
    return {
      cacheEnabled: false,
      pwaUploadRequests: {
        errors: [],
        state: false,
      },
      requestTypes: {
        pest: 'PEST_TYPE',
        protection: 'PROTECTION_TYPE',
      },
    };
  },
  computed: {
    ...mapGetters({
      isPwaEnabled: 'pwa/isPwaEnabled',
      pwaStructure: 'pwa/pwaStructure',
      pwaUserData: 'pwa/pwaUserData',
      levelComposition: 'pwa/levelComposition',
    }),
    pwaUploadErrors() {
      const pwaUploadErrors = [];

      _.forEach(this.pwaUploadRequests.errors, (error) => {
        // eslint-disable-next-line max-len
        pwaUploadErrors.push(`- блок ${error.get('blockId')}, дом ${error.get('houseId')}, ряд ${error.get('row')}, ячейка ${error.get('cellId')}`);
      });

      return pwaUploadErrors;
    },
  },
  methods: {
    ...mapMutations({
      enablePwa: 'pwa/enablePwa',
      disablePwa: 'pwa/disablePwa',
      setPwaStructure: 'pwa/setPwaStructure',
      setPwaUserData: 'pwa/setPwaUserData',
      setLevelComposition: 'pwa/setLevelComposition',
    }),
    pwaOff() {
      console.warn('pwaOff -- init');

      if (!this.checkOnline()) {
        console.warn('pwaOff', 'Нельзя выйти при выключенном интернете');
        this.$message.errorMessage('Нельзя выйти при выключенном интернете');
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const exit = setTimeout(() => {
        try {
          caches.open('app-cache-v1.27').then((cache) => {
            cache.keys().then((keys) => {
              _.forEach(keys, (value, index) => {
                cache.delete(keys[index]);
              });
            });
          }).catch((error) => {
            this.$message.errorMessage(error);
          });

          console.warn('pwaOff -- unregister worker');

          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
              for (const registration of registrations) {
                registration.unregister();
              }
            }).catch((error) => {
              // eslint-disable-next-line no-console
              console.warn('Service Worker registration failed: ', error);
              this.$_API_HELPER.redirectToAgronom(this.$alert);

              // this.$message.errorMessage(error.toString());
            });
          } else {
            this.$message.errorMessage('Браузер не поддерживает ServiceWorkers');
          }
        } catch (error) {
          // this.$message.errorMessage(error.toString());
        }

        console.warn('pwaOff', 'redirectToAgronom');
        this.$_API_HELPER.redirectToAgronom(this.$alert);
      }, 1500);
    },
    async loadReq(exit = false) {
      if (!navigator.onLine) {
        this.$message.errorMessage('Данные мониторинга не выгружены. Проверьте интернет соединение');
        return;
      }

      // this.$message.message('Проверяем кэш');

      const checker = await this.checkRequestData();
      if (!checker && exit) {
        this.pwaOff();
      }

      const request = indexedDB.open(DATA_BASE_REQUEST);

      try {
        request.onerror = (event) => {
          console.error(event);
          this.$message.message(event.toString());

          if (exit) {
            this.pwaOff();
          }
        };
        request.onsuccess = async (event) => {
          let isNotEmpty = true;
          let needProcessPest = true;
          let needProcessProtection = true;

          const db = event.target.result;

          // CHECK TABLE PESTS
          if (!db.objectStoreNames.contains(TABLE_REQUEST_PEST)) {
            needProcessPest = false;
          }

          // PROCESS PEST
          if (needProcessPest) {
            const store = await db.transaction(TABLE_REQUEST_PEST, DATA_BASE_ACCESS_RO).objectStore(TABLE_REQUEST_PEST);
            const request = await store.getAll();
            request.onsuccess = async () => {
              isNotEmpty = request.result.length;

              _.forEach(request.result, async (requestPostData, key) => {
                const postFormData = this.preparePestData(requestPostData);
                this.$_API.post(this.$_API_ROUTES.cell.addPest, postFormData, {
                  headers: this.$_Auth.getAuthHeader(),
                }).then(({data}) => {
                  if (data.success) {
                    // this.$message.saved();
                    return Promise.resolve(true);
                  }

                  this.pushPwaUploadRequestsError(postFormData);
                  return Promise.resolve(true);
                });
              });

              if (isNotEmpty) {
                this.clearTable(db, TABLE_REQUEST_PEST);
              } else {
                needProcessPest = false;
              }
            };
            request.onerror = () => console.error('Ошибка', request.error);
          }

          // CHECK TABLE PROTECTIONS
          if (!db.objectStoreNames.contains(TABLE_REQUEST_PEST)) {
            needProcessProtection = false;
          }

          // PROCESS PROTECTION
          if (needProcessProtection) {
            // eslint-disable-next-line max-len
            const store = await db.transaction(TABLE_REQUEST_PROTECTION, DATA_BASE_ACCESS_RO).objectStore(TABLE_REQUEST_PROTECTION);
            const request = await store.getAll();
            request.onsuccess = async () => {
              isNotEmpty = request.result.length;

              _.forEach(request.result, async (requestPostData, key) => {
                const postFormData = this.prepareProtectionData(requestPostData);
                this.$_API.post(this.$_API_ROUTES.cell.addProtection, postFormData, {
                  headers: this.$_Auth.getAuthHeader(),
                }).then(({data}) => {
                  if (data.success) {
                    // this.$message.saved();
                    return;
                  }

                  this.pushPwaUploadRequestsError(postFormData);
                  return Promise.resolve(true);
                });
              });

              if (isNotEmpty) {
                this.clearTable(db, TABLE_REQUEST_PROTECTION);
              } else {
                needProcessProtection = false;

                if (!needProcessPest && !needProcessProtection) {
                  this.$message.message('Нет новых данных для выгрузки');
                } else {
                  this.$message.successMessage('Данные мониторинга успешно сохранены');
                }
              }

              if (exit) {
                this.pwaOff();
              }
            };
            request.onerror = () => console.error('Ошибка', request.error);
          }

          return Promise.resolve();
        };
      } catch (e) {
        console.error(e);
        if (exit) {
          this.pwaOff();
        }
        return Promise.reject(e);
      }
    },
    async checkRequestsTableExist(isProtection = false) {
      const table = isProtection ? TABLE_REQUEST_PROTECTION : TABLE_REQUEST_PEST;
      console.warn(`checkRequestsTableExist - ${table}`);

      return new Promise(async (resolve, reject) => {
        const db = indexedDB.open(DATA_BASE_REQUEST, 1);

        db.onsuccess = async (event) => {
          console.warn('checkRequestsTableExist -- onsuccess');

          const db = event.target.result;
          const exist = await db.objectStoreNames.contains(table);
          db.close();

          console.warn(`checkRequestsTableExist - ${table} - ${exist}`);
          return resolve(exist);
        };

        db.onblocked = async (event) => {
          console.warn('checkRequestsDataExist -- onblocked');
        };

        db.onerror = async (event) => {
          console.warn('checkRequestsDataExist -- onerror');
        };

        db.onupgradeneeded = async (event) => {
          console.warn('checkRequestsTableExist -- onupgradeneeded');

          const db = event.target.result;
          const exist = await db.objectStoreNames.contains(table);
          db.close();

          console.warn(`checkRequestsTableExist - ${table} - ${exist}`);
          return resolve(exist);
        };
      });
    },
    async checkRequestsDataExist(isProtection = false) {
      const table = isProtection ? TABLE_REQUEST_PROTECTION : TABLE_REQUEST_PEST;
      console.warn(`checkRequestsDataExist - ${table}`);

      return new Promise(async (resolve, reject) => {
        const db = indexedDB.open(DATA_BASE_REQUEST, 1);
        console.warn(`checkRequestsDataExist - open`);

        db.onsuccess = async (event) => {
          console.warn('checkRequestsDataExist -- onsuccess');

          const db = event.target.result;
          const exist = await db.objectStoreNames.contains(table);

          if (!exist) {
            console.warn('Not exist: ', table);
            return resolve(false);
          }

          const tableResult = await db.transaction(table, DATA_BASE_ACCESS_RO).objectStore(table);
          const resultData = await tableResult.getAll();
          db.close();

          const checkIt = await this.checkIt(resultData);

          console.warn('exist: ', table, checkIt);
          resolve(checkIt);
        };

        db.onblocked = async (event) => {
          console.warn('checkRequestsDataExist -- onblocked');
        };

        db.onerror = async (event) => {
          console.warn('checkRequestsDataExist -- onerror');
        };

        db.onupgradeneeded = async (event) => {
          console.warn('checkRequestsDataExist -- onupgradeneeded');

          const db = event.target.result;
          const exist = await db.objectStoreNames.contains(table);

          if (!exist) {
            console.warn('Not exist: ', table);
            return resolve(false);
          }

          const tableResult = await db.transaction(table, DATA_BASE_ACCESS_RO).objectStore(table);
          const resultData = await tableResult.getAll();
          db.close();

          const checkIt = await this.checkIt(resultData);

          console.warn('exist: ', table, checkIt);
          resolve(checkIt);
        };

        console.warn(`checkRequestsDataExist - ${table} - end`);
      });
    },
    async checkIt(resultData) {
      return new Promise(((resolve, reject) => {
        resultData.onsuccess = () => {
          if (resultData.result.length > 0) {
            console.warn('resolve true');
            resolve(true);
          } else {
            console.warn('resolve false');
            resolve(false);
          }
        };
      }));
    },
    async checkRequestData() {
      console.warn('CHECK REQUEST DATA');

      return new Promise((async (resolve, reject) => {
        const pest = await this.checkRequestsDataExist(false);
        if (pest) {
          console.warn('checkRequestData -- resolve -- pest');
          return resolve(true);
        }

        const protection = await this.checkRequestsDataExist(true);
        if (protection) {
          console.warn('checkRequestData -- resolve -- protection');
          return resolve(true);
        }

        const pestExist = await this.checkRequestsTableExist(false);
        const protectionExist = await this.checkRequestsTableExist(true);
        if (!pestExist && !protectionExist) {
          await this.recreateRequestDB();
        }

        console.warn('checkRequestData -- resolve');
        resolve(false);
      }));
    },
    prepareProtectionData(requestPostData) {
      const postFormData = new FormData();

      postFormData.append('greenhouseId', requestPostData.greenhouseId);
      postFormData.append('blockId', requestPostData.blockId);
      postFormData.append('houseId', requestPostData.houseId);
      postFormData.append('row', requestPostData.row);
      postFormData.append('lat', requestPostData.lat);
      postFormData.append('lon', requestPostData.lon);
      postFormData.append('cellId', requestPostData.cellId);
      postFormData.append('operation_date', requestPostData.operation_date);
      postFormData.append('protections', requestPostData.protections);

      _.forEach(requestPostData['files'], (fileData) => {
        postFormData.append('files[]', fileData);
      });

      return postFormData;
    },
    preparePestData(requestPostData) {
      const postFormData = new FormData();

      postFormData.append('greenhouseId', requestPostData.greenhouseId);
      postFormData.append('blockId', requestPostData.blockId);
      postFormData.append('houseId', requestPostData.houseId);
      postFormData.append('row', requestPostData.row);
      postFormData.append('lat', requestPostData.lat);
      postFormData.append('lon', requestPostData.lon);
      postFormData.append('cellId', requestPostData.cellId);
      postFormData.append('operation_date', requestPostData.operation_date);
      postFormData.append('infections', requestPostData.infections);

      _.forEach(requestPostData['files'], (fileData) => {
        postFormData.append('files[]', fileData);
      });

      return postFormData;
    },
    clearTable(db, table) {
      const storeClear = db.objectStoreNames.contains(table)
                         ? db.transaction(table, DATA_BASE_ACCESS_RW).objectStore(table)
                         : [];

      const clearRequest = storeClear.clear();
      clearRequest.onsuccess = (event) => console.warn(`Очищен стор: ${table}`);
      clearRequest.onerror = (event) => console.warn('Ошибка: ' + clearRequest.error);
    },
    async addToCacheRequest(requestType, requestData) {
      if (Object.keys(REQUEST_TYPE_MAP).indexOf(requestType) === -1) {
        return Promise.reject(new Error(`UNKNOWN REQUEST TYPE: ${requestType}`));
      }

      const dbRequestType = REQUEST_TYPE_MAP[requestType];
      const request = indexedDB.open(DATA_BASE_REQUEST);

      let isProcessed = false;

      try {
        request.onerror = (event) => console.error(event);
        request.onsuccess = async (event) => {
          console.warn('onsuccess addToCacheRequest');
          if (!isProcessed) {
            await this.addRequest(event, requestData, dbRequestType).then((data) => {
              switch (REQUEST_TYPE_MAP[requestType]) {
                case REQUEST_TYPE_MAP[this.requestTypes.pest]:
                  this.changeNeedCheckCell();
                  this.$message.savedPwaItem();
                  break;
                case REQUEST_TYPE_MAP[this.requestTypes.protection]:
                  this.changeDoneCell();
                  this.$message.savedPwaItem();
                  break;
              }
            });
          }
        };

        request.onupgradeneeded = async (event) => {
          console.warn('onupgradeneeded addToCacheRequest');
          isProcessed = true;

          await this.addRequest(event, requestData, dbRequestType).then((data) => {
            switch (REQUEST_TYPE_MAP[requestType]) {
              case REQUEST_TYPE_MAP[this.requestTypes.pest]:
                this.changeNeedCheckCell();
                this.$message.savedPwaItem();
                break;
              case REQUEST_TYPE_MAP[this.requestTypes.protection]:
                this.changeDoneCell();
                this.$message.savedPwaItem();
                break;
            }
          });
        };
      } catch (e) {
        console.error(e);
        return new Error();
      }

      return Promise.resolve(true);
    },
    async addRequest(event, requestData, dbRequestType) {
      console.warn('addRequest');
      const db = event.target.result;

      console.warn(dbRequestType, 'dbRequestType');
      const store = db.objectStoreNames.contains(dbRequestType)
                    ? db.transaction(dbRequestType, DATA_BASE_ACCESS_RW).objectStore(dbRequestType)
                    : db.createObjectStore(dbRequestType, {autoIncrement: true});

      const request = store.put(requestData);
      request.onsuccess = async () => {
        console.warn(`Запрос добавлен в хранилище: ${dbRequestType}`);
      };
      request.onerror = () => console.error('Ошибка', request.error);

      await new Promise((resolve, reject) => setTimeout(resolve, 200));
    },
    async recalcNeedCheck() {
      console.warn('recalcNeedCheck');

      let needCheckHouse = false;
      let needCheckBlock = false;
      let needUpdateStructure = false;

      _.forEach(this.pwaStructure, (block) => {
        // GET CURRENT BLOCK
        if (parseInt(block.blockId) === this.levelComposition.blockId) {
          // console.warn(block.blockId, 'block.blockId');
          // GET CELL`S ROWS IN CURRENT HOUSE
          _.forEach(block['houses'][this.levelComposition.houseId], (rowData, rowId) => {
            // console.warn(rowId, 'rowId');
            _.forEach(rowData, (cellData, cellId) => {
              // console.warn(cellId, 'cellId');
              // IF AT LEAST ONE CELL NEED TO CHECK, SET FLAG & SKIP ANOTHER CELLS
              if (cellData.needCheck === '1') {
                needCheckHouse = true;
                return false;
              }
            });

            // SKIP ANOTHER ROWS, IF ALREADY FOUND CELL NEED TO CHECK
            if (needCheckHouse) {
              return false;
            }
          });

          // SKIP ANOTHER BLOCKS
          return false;
        }
      });

      if (!needCheckHouse) {
        _.forEach(this.pwaUserData.greenhouseData.greenhouseHouses, (house, index) => {
          // GET CURRENT BLOCK
          if (house.block_id === this.levelComposition.blockId) {
            // console.warn(house.block_id, 'house.blockId');
            // GET CURRENT HOUSE, SET VALUE & SKIP
            if (house.real_id === this.levelComposition.houseId) {
              console.warn(house.real_id, 'house.real_id');
              console.warn(index, 'house index');
              // console.warn(house.needCheck, 'house.needCheck');
              this.$set(this.pwaUserData.greenhouseData.greenhouseHouses[index], 'needCheck', false);

              // UPDATE DATA IN THE BROWSER DB
              needUpdateStructure = true;

              return true;
            }

            // IF AT LEAST ONE HOUSE NEED TO CHECK, SET FLAG & SKIP ANOTHER HOUSES IN A CURRENT BLOCK
            if (house.needCheck) {
              // console.warn(house.real_id, 'house.real_id');
              needCheckBlock = true;
            }
          }
        });
      }

      // IF NO ONE HOUSE IN A CURRENT BLOCK DON`T NEED TO CHECK, SET FLAG TO A CURRENT BLOCK
      if (!needCheckHouse && !needCheckBlock) {
        _.forEach(this.pwaUserData.greenhouseData.greenhouseBlocks, (block, index) => {
          if (block.real_id === this.levelComposition.blockId && block.needCheck) {
            this.$set(this.pwaUserData.greenhouseData.greenhouseBlocks[index], 'needCheck', false);

            // UPDATE DATA IN THE BROWSER DB
            needUpdateStructure = true;

            // SKIP ANOTHER BLOCKS
            return false;
          }
        });
      }

      console.warn(needCheckHouse, 'needCheckHouse');
      console.warn(needCheckBlock, 'needCheckBlock');
      console.warn(needUpdateStructure, 'needUpdateStructure');

      // UPDATE BROWSER DATA, IF LOCAL DATA HAS BEEN UPDATED ABOVE
      if (needUpdateStructure) {
        await this.saveUserData(this.pwaUserData);
      }
    },
    saveStructureFromStore() {
      const structureToSave = {};

      _.forEach(this.pwaStructure, (block) => {
        structureToSave[block.blockId] = block;
      });

      this.saveStructure(structureToSave);
    },
    changeNeedCheckCell() {
      console.warn('changeNeedCheckCell');

      _.forEach(this.pwaStructure, (block, itemIndex) => {
        if (parseInt(block.blockId) === this.levelComposition.blockId) {
          console.warn(this.levelComposition, 'this.levelComposition');
          console.warn(this.levelComposition, 'this.levelComposition');
          // eslint-disable-next-line max-len
          this.$set(this.pwaStructure[itemIndex].houses[this.levelComposition.houseId][this.levelComposition.rowId][this.levelComposition.cellId], 'needCheck', '0');

          this.recalcNeedCheck().then(() => {
            this.saveStructureFromStore();
          });
        }
      });
    },
    changeDoneCell() {
      console.warn('changeDoneCell');
      _.forEach(this.pwaStructure, (block, itemIndex) => {
        if (parseInt(block.blockId) === this.levelComposition.blockId) {
          // eslint-disable-next-line max-len
          this.$set(this.pwaStructure[itemIndex].houses[this.levelComposition.houseId][this.levelComposition.rowId][this.levelComposition.cellId], 'done', true);

          this.saveStructureFromStore();
        }
      });
    },
    getStructure() {
      const request = indexedDB.open(DATA_BASE_STRUCTURE);

      request.onerror = (event) => console.error(event);
      request.onsuccess = (event) => this.setStructureToStore(event);
      request.onupgradeneeded = (event) => this.setStructureToStore(event);
    },
    setStructureToStore(event) {
      const db = event.target.result;
      const transaction = db.transaction(TABLE_BLOCK, DATA_BASE_ACCESS_RW).objectStore(TABLE_BLOCK);
      const transactionRequest = transaction.getAll();

      transactionRequest.onsuccess = (event) => this.setPwaStructure(event.target.result);
      transactionRequest.onerror = (event) => {
        this.$message.errorMessage('Ошибка загрузки структуры');
        console.error(event);
      };
    },
    getUserData() {
      const request = indexedDB.open(DATA_BASE_USER);

      request.onerror = (event) => console.error(event);
      request.onsuccess = (event) => this.setUserDataToStore(event);
      request.onupgradeneeded = (event) => this.setUserDataToStore(event);
    },
    setUserDataToStore(event) {
      const db = event.target.result;
      const transaction = db.transaction(TABLE_USER_DATA, DATA_BASE_ACCESS_RW).objectStore(TABLE_USER_DATA);
      const transactionRequest = transaction.getAll();

      transactionRequest.onsuccess = (event) => this.setPwaUserData(event.target.result[0]);
      transactionRequest.onerror = (event) => {
        this.$message.errorMessage('Ошибка загрузки структуры');
        console.error(event);
      };
    },
    async saveStructure(data) {
      const request = indexedDB.open(DATA_BASE_STRUCTURE);

      request.onerror = (event) => {
        console.error(event, 'saveStructure');
        this.$message.errorMessage(event.toString());
      };
      request.onsuccess = async (event) => {
        this.parseAndSaveStructure(event, data);
        return Promise.resolve(true);
      };
      request.onupgradeneeded = async (event) => {
        this.parseAndSaveStructure(event, data);
        return Promise.resolve(true);
      };
    },
    parseAndSaveStructure(event, data) {
      const db = event.target.result;

      const blocks = db.objectStoreNames.contains(TABLE_BLOCK)
                     ? db.transaction(TABLE_BLOCK, DATA_BASE_ACCESS_RW).objectStore(TABLE_BLOCK)
                     : db.createObjectStore(TABLE_BLOCK, {autoIncrement: false});

      _.forEach(data, (blockData, blockId) => {
        const request = blocks.put({blockId: blockId, ...blockData}, blockId);

        request.onsuccess = () => console.warn(`Блок ${blockId} добавлен в хранилище`, request.result);
        request.onerror = () => {
          console.error('Ошибка', request.error);
          this.$message.errorMessage(request.error.toString());
        };
      });
    },
    async parseAndSaveUserData(event, userData) {
      const db = event.target.result;

      const store = db.objectStoreNames.contains(TABLE_USER_DATA)
                     ? db.transaction(TABLE_USER_DATA, DATA_BASE_ACCESS_RW).objectStore(TABLE_USER_DATA)
                     : db.createObjectStore(TABLE_USER_DATA, {autoIncrement: true});

      const request = store.put(userData, 'userData');
      request.onsuccess = async () => {
        console.warn('Пользователь добавлен в хранилище', request.result);
        return Promise.resolve(true);
      };
      request.onerror = async () => {
        console.error('Ошибка', request.error);
        this.$message.errorMessage(request.error.toString());
        throw new Error();
      };

      await request.onsuccess;
    },
    async saveUserData(userData) {
      const request = await indexedDB.open(DATA_BASE_USER);

      request.onerror = (event) => {
        console.error(event, 'saveUserData');
        this.$message.errorMessage(event.toString());
        throw new Error();
      };
      request.onsuccess = async (event) => {
        await this.parseAndSaveUserData(event, userData);
        return Promise.resolve(true);
      };
      request.onupgradeneeded = async (event) => {
        await this.parseAndSaveUserData(event, userData);
        return Promise.resolve(true);
      };

      await request.onsuccess;
      await request.onupgradeneeded;
    },
    async initOfflineDBSchema() {
      console.warn('initOfflineDBSchema');

      return new Promise(async (resolve, reject) => {
        _.forEach([DATA_BASE_STRUCTURE, DATA_BASE_USER, DATA_BASE_REQUEST], async (DB) => {
          await this.deleteOfflineDBSchema(DB).catch((error) => console.error(error));
        });

        const responseSchema = indexedDB.open(DATA_BASE_REQUEST);
        responseSchema.onupgradeneeded = (event) => {
          console.warn('initOfflineDBSchema responseSchema.onupgradeneeded');
          const creator = event.target.result;
          creator.createObjectStore(TABLE_REQUEST_PROTECTION, {autoIncrement: true});
          creator.createObjectStore(TABLE_REQUEST_PEST, {autoIncrement: true});
          return resolve(true);
        };

        return resolve(false);
      });
    },
    async recreateRequestDB(redirected = false) {
      console.warn('recreateRequestDB');

      return new Promise(async (resolve, reject) => {
        const responseSchema = indexedDB.open(DATA_BASE_REQUEST);
        console.warn('recreateRequestDB - open DB');

        responseSchema.onupgradeneeded = async (event) => {
          console.warn('recreateRequestDB responseSchema.onupgradeneeded');
          const creator = responseSchema.result;
          creator.createObjectStore(TABLE_REQUEST_PROTECTION, {autoIncrement: true});
          creator.createObjectStore(TABLE_REQUEST_PEST, {autoIncrement: true});
          creator.close();

          return resolve(true);
        };

        responseSchema.onsuccess = async (event) => {
          if (redirected) {
            const DB = responseSchema.result;
            DB.close();

            this.$set(this, 'cacheEnabled', true);
            return resolve(true);
          }

          console.warn('recreateRequestDB responseSchema.onsuccess');
          await this.deleteOfflineDBSchema(DATA_BASE_REQUEST).catch((error) => console.error(error));
          await this.recreateRequestDB(true);
        };

        this.$set(this, 'cacheEnabled', true);
        return resolve(true);
      });
    },
    async deleteOfflineDBSchema(DB) {
      console.warn(`Clear: DB '${DB}'`);

      const deleteRequest = await indexedDB.deleteDatabase(DB);
      deleteRequest.onsuccess = async (event) => Promise.resolve(true);
      deleteRequest.onerror = async (event) => new Error(`${DB} CLEAR ERROR: ${JSON.stringify(event)}`);

      await deleteRequest.onsuccess;
      await deleteRequest.onerror;
    },
    loadPwaStructure(greenhouseId, userId, userData) {
      console.warn('Load PWA structure');
      this.$emit('changeLoader', true);

      const route = this.$_API_ROUTES.app.pwaStructure;
      const postData = {
        userId: userId,
        greenhouseId: greenhouseId,
      };

      this.$_API.post(route, postData, {
        headers: this.$_Auth.getAuthHeader(),
      }).then(({data}) => {
        if (data.success) {
          this.saveStructure(data.structure).then(() => {
            setTimeout(() => {
              this.saveUserData(userData).then(() => {
                this.$_API_HELPER.redirectToPWA();
                this.$emit('changeLoader', false);
              });
            }, 2500);
          });
          return;
        }

        this.$emit('changeLoader', false);
        this.$errors.parseErrors(data.errors);
      }).catch((error) => {
        this.$emit('changeLoader', false);
        console.error(`loadPwaStructure: ${error.toString()}`);
        this.$message.errorMessage('Произошла ошибка. Попробуйте еще раз');
      });
    },
    pushPwaUploadRequestsError(error) {
      const copy = _.cloneDeep(this.pwaUploadRequests.errors);
      copy.push(error);
      this.$set(this.pwaUploadRequests, 'errors', copy);
    },
    clearPwaUploadRequestsErrors() {
      this.$set(this.pwaUploadRequests, 'errors', []);
    },
    changePwaUploadRequestsState() {
      this.$set(this.pwaUploadRequests, 'state', !this.pwaUploadRequests.state);
    },
  },
};
