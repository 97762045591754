import {FUNCTIONALITY_LIST_FULL} from '../components/admin/functionality-change/FunctionalityViewReadOnly';
import {
  LIST_ROLES_WITH_FULL_OBJECT_ACCESS,
  ROLE_SYSTEM_ROOT,
  ROLE_SYSTEM_SUPER_ADMIN, LIST_ROLES_CAN_DELEGATE_COMPANY_SUPER_ADMIN
} from '../components/admin/RoleList';

const StoreAdmin = {
  namespaced: true,
  state: {
    modalState: false,
    user: {},
    accountEditorState: {},

    administratorEditorId: 0,
    administratorEditorState: {},
    administratorEditorAccess: {},

    companyEditorId: 0,
    companyEditorState: {},

    administratorsAddState: {},
    administratorsAddAccess: {},
    // super - for superadministrator create, company - for company admins create
    administratorsAddStateType: 'company',
    administratorsAddStateCompanyId: 0,

    employeeEditorId: 0,
    employeeNeedUpdate: false,
    employeeEditorState: {},

    employeeAddState: {},
    employeeAddStateCompanyId: 0,

    helpState: {},
  },
  getters: {
    modalState: (state) => state.modalState,

    user: (state) => state.user,

    userSuperAdmin: state => _.indexOf([ROLE_SYSTEM_ROOT, ROLE_SYSTEM_SUPER_ADMIN], state.user.role) !== -1,

    userCanDelegateCompanySuperAdmin: state => _.indexOf(
      LIST_ROLES_CAN_DELEGATE_COMPANY_SUPER_ADMIN,
      state.user.role
    ) !== -1,

    userAccessUnlimited: state => _.indexOf(LIST_ROLES_WITH_FULL_OBJECT_ACCESS, state.user.role) !== -1,
    userAccess: (state) => {
      const userAccess = {};

      if (state.user.access?.objects) {
        _.forEach(state.user.access.objects, object => {
          const ghId = Number.parseInt(object.greenhouse_id);

          if (userAccess[ghId] === undefined) {
            userAccess[ghId] = {};
          }

          const blockName = object.all_blocks === '1' ? 'all_blocks' : Number.parseInt(object.block_id);
          userAccess[ghId][blockName] = {};

          Object.keys(FUNCTIONALITY_LIST_FULL).filter(id => id > 0).forEach(functionalityId => {
            const value = object.functionality[functionalityId] && object.functionality[functionalityId].allow === '1';
            userAccess[ghId][blockName][functionalityId] = value;
          });
        });
      }

      return userAccess;
    },

    helpState: (state) => state.helpState,

    accountEditorState: (state) => state.accountEditorState,

    administratorEditorId: (state) => state.administratorEditorId,
    administratorEditorState: (state) => state.administratorEditorState,
    administratorEditorAccess: (state) => state.administratorEditorAccess,

    employeeEditorId: (state) => state.employeeEditorId,
    employeeNeedUpdate: (state) => state.employeeNeedUpdate,
    employeeEditorState: (state) => state.employeeEditorState,

    employeeAddState: (state) => state.employeeAddState,
    employeeAddStateCompanyId: (state) => state.employeeAddStateCompanyId,

    companyEditorId: (state) => state.companyEditorId,
    companyEditorState: (state) => state.companyEditorState,

    administratorsAddState: (state) => state.administratorsAddState,
    administratorsAddAccess: (state) => state.administratorsAddAccess,
    administratorsAddStateType: (state) => state.administratorsAddStateType,
    administratorsAddStateCompanyId: (state) => state.administratorsAddStateCompanyId,
  },
  mutations: {
    setModalState(state, value) {
      state.modalState = value;
    },
    setAccountEditorState(state, value) {
      _.forIn(value, (data, key) => {
        state.accountEditorState[key] = data;
      });
    },

    setUser(state, value) {
      state.user = value;
    },

    // COMPANY
    setCompanyEditorState(state, value) {
      state.companyEditorState = value;
    },
    setCompanyEditorId(state, value) {
      state.companyEditorId = value;
    },
    clearCompanyEditorState(state) {
      state.companyEditorState = {};
    },
    clearCompanyEditorId(state) {
      state.companyEditorId = 0;
    },

    // ADMINISTRATOR
    setAdministratorEditorId(state, value) {
      state.administratorEditorId = value;
    },
    setAdministratorsAddState(state, value) {
      state.administratorsAddState = {};

      _.forIn(value, (data, key) => {
        state.administratorsAddState[key] = data;
      });
    },
    setAdministratorsAddAccess(state, value) {
      state.administratorsAddAccess = {};

      _.forIn(value, (data, key) => {
        state.administratorsAddAccess[key] = data;
      });
    },
    setAdministratorsAddStateType(state, value) {
      state.administratorsAddStateType = value;
    },
    setAdministratorsAddStateCompanyId(state, value) {
      state.administratorsAddStateCompanyId = value;
    },
    setAdministratorEditorState(state, value) {
      state.administratorEditorState = {};

      _.forIn(value, (data, key) => {
        state.administratorEditorState[key] = data;
      });
    },
    setAdministratorEditorAccess(state, value) {
      state.administratorEditorAccess = {};

      _.forIn(value, (data, key) => {
        state.administratorEditorAccess[key] = data;
      });
    },
    clearAdministratorEditorId(state) {
      state.administratorEditorId = 0;
    },

    // EMPLOYEE
    setEmployeeEditorId(state, value) {
      state.employeeEditorId = value;
    },
    setEmployeeNeedUpdate(state, value) {
      state.employeeNeedUpdate = value;
    },
    setEmployeeEditorState(state, value) {
      state.employeeEditorState = {};

      _.forIn(value, (data, key) => {
        state.employeeEditorState[key] = data;
      });
    },
    setEmployeeAddState(state, value) {
      state.employeeAddState = {};

      _.forIn(value, (data, key) => {
        state.employeeAddState[key] = data;
      });
    },
    setEmployeeAddStateCompanyId(state, value) {
      state.employeeAddStateCompanyId = value;
    },

    // HELP
    setHelpState(state, value) {
      _.forIn(value, (data, key) => {
        state.helpState[key] = data;
      });
    },
    clearHelpState(state) {
      state.helpState = {};
    },
  },
};

export default StoreAdmin;
