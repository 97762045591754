/**
 * Auth service helper.
 */
import AuthHelper from './AuthHelper';

const AuthService = {
  install(Vue) {
    Vue.prototype.$_Auth = AuthHelper;
  },
};

export default AuthService;
