export default {
  data() {
    return {
      geolocation: {},
    };
  },
  mounted() {
    this.initGeo();
  },
  activated() {
    this.initGeo();
  },
  methods: {
    initGeo() {
      navigator.geolocation.getCurrentPosition(
        (position) => this.geolocation = position.coords,
        (error) => {
          this.$message.errorMessage('Нет доступа к геолокации!');
          throw new Error(error.message);
        });
    },
  },
};
