const StorePWA = {
  namespaced: true,
  state: {
    isPwaEnabled: false,
    pwaStructure: [],
    pwaUserData: {},
    levelComposition: {
      page: 1,
      blockId: 0,
      houseId: 0,
      rowId: 0,
      cellId: 0,
    },
  },
  getters: {
    isPwaEnabled: (state) => state.isPwaEnabled,
    pwaStructure: (state) => state.pwaStructure,
    pwaUserData: (state) => state.pwaUserData,
    levelComposition: (state) => state.levelComposition,
  },
  mutations: {
    setPwaStructure(state, structure) {
      state.pwaStructure = {};

      _.forEach(structure, (blockData, blockId) => {
        state.pwaStructure[blockId] = blockData;
      });
    },
    setPwaUserData(state, pwaUserData) {
      state.pwaUserData = {};

      _.forEach(pwaUserData, (data, key) => {
        state.pwaUserData[key] = data;
      });
    },
    setLevelComposition(state, levelComposition) {
      state.levelComposition = {};

      // PROCESS BROWSER HISTORY
      let browserLocation = levelComposition['page'];

      switch (levelComposition['page']) {
        case 2:
          browserLocation = `${browserLocation}_${levelComposition['blockId']}`;
          break;
        case 3:
          browserLocation = `${browserLocation}_${levelComposition['blockId']}_${levelComposition['houseId']}`;
          break;
        case 4:
        case 5:
        case 6:
          // eslint-disable-next-line max-len
          browserLocation = `${browserLocation}_${levelComposition['blockId']}_${levelComposition['houseId']}_${levelComposition['rowId']}_${levelComposition['cellId']}`;
          break;
      }

      window.location.hash = browserLocation;

      _.forEach(levelComposition, (data, key) => {
        if ((key === 'cellId' || key === 'rowId') && levelComposition['page'] < 4) {
          data = 0;
        }

        state.levelComposition[key] = data;
      });
    },
    enablePwa(state) {
      state.isPwaEnabled = true;
    },
    disablePwa(state) {
      state.isPwaEnabled = false;
    },
  },
};

export default StorePWA;
