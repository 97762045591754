<template>
    <div :style="getStyleObject()" :disabled="disabled" @click="$emit('button-click')">
        {{ text }}
    </div>
</template>

<script>
export default {
  name: 'Buttons',
  props: {
    type: {
      required: true,
      type: String,
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Принять',
    },
    mRight: {
      type: [Number, String],
      default: 0,
    },
    mLeft: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    // TODO: move styles to v-button styles file and use getClassesObject instead of the getStyleObject
    getStyleObject() {
      const typeConfig = this.$_CONFIG.BUTTON_TYPES[this.type];

      if (typeConfig === undefined) {
        console.error(`Trying to get not defined button type ${this.type}`);
        return {};
      }

      const buttonStyle = {
        // Container styles
        backgroundColor: typeConfig.backgroundColor,
        width: `${this.width}px`,
        height: `${this.height}px`,
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: '5',

        // Text styles
        color: typeConfig.fontColor,
        textTransform: 'uppercase',
        fontFamily: 'Proxima Nova',
        fontStyle: 'normal',
        lineHeight: '17px',
        fontSize: '14px',
        fontWeight: '500',
      };

      if (this.mLeft) {
        buttonStyle.marginLeft = `${this.mLeft}px`;
      }

      if (this.mRight) {
        buttonStyle.marginRight = `${this.mRight}px`;
      }

      if (typeConfig.border) {
        buttonStyle.border = `1px ${typeConfig.borderColor} solid`;
      }

      return buttonStyle;
    },
  },
};
</script>

<style scoped>

</style>
