import {
  ROLE_COMPANY_SUPER_ADMINISTRATOR,
  ROLE_COMPANY_ADMINISTRATOR,
  ROLE_COMPANY_FINANCIER, ROLE_SYSTEM_ROOT
} from '../components/admin/RoleList';

export default {
  created() {
    // SPECIFIC ACCOUNT DATA
    if (navigator.onLine) {
      this.initUserAccountInfo();
    }

    // META DATA
    this.initMetaCurrency();
    this.initMetaCultures();
    this.initMetaPests();
    this.initMetaProtection();
  },
  methods: {
    initAdminCampaignData() {
      if (!this.isUserAdmin) {
        const route = `${this.$_API_ROUTES.company.companies}${this.userData.company_id}/`;

        this.$_API.get(route, {
          headers: {...this.$_Auth.getAuthHeader()},
        }).then(({data}) => {
          if (!_.has(data, 'error')) {
            this.setCompanyData(data);
          }
        });
      }
    },
    initUserAccountInfo() {
      this.$_API.post(`${this.$_API_ROUTES.user.userData}?expand=access`, {}, {
        headers: {...this.$_Auth.getAuthHeader()},
      }).then(({data}) => {
        if (data.id) {
          if (_.indexOf([
            ROLE_COMPANY_ADMINISTRATOR,
            ROLE_COMPANY_FINANCIER,
            ROLE_COMPANY_FINANCIER,
            ROLE_SYSTEM_ROOT,
            ROLE_COMPANY_SUPER_ADMINISTRATOR
          ], data.role) === -1) {
            if (_.indexOf(['agronomist', 'monitor'], data.role) !== -1) {
              this.$_API_HELPER.redirectToAgronom();
              return;
            }

            this.$_API_HELPER.redirectToError404();
            return;
          }

          this.setUserData(data);
          this.$_Auth.setAuthPermission(this.$_Auth.getAuthToken(), data.id);
        }
      }).then(() => {
        this.initAdminCampaignData();
        this.getLocationMethod();
      });
    },
    initMetaCurrency() {
      this.$_API.get(this.$_API_ROUTES.app.currency, {
        headers: {...this.$_Auth.getAuthHeader()},
      }).then(({data}) => {
        this.setCurrency(data.items);
      });
    },
    initMetaPests() {
      this.$_API.get(this.$_API_ROUTES.app.pests, {
        headers: {...this.$_Auth.getAuthHeader()},
      }).then(({data}) => {
        this.setPests(data.items);
      });
    },
    initMetaProtection() {
      this.$_API.get(this.$_API_ROUTES.app.protections, {
        headers: {...this.$_Auth.getAuthHeader()},
      }).then(({data}) => {
        this.setProtections(data.items);
      });
    },
    initMetaProtectionCropMapper() {
      // const postData = {
      //   greenhouseId: this.userData.greenhouse_id,
      //   userId: this.userData.id,
      // };

      // console.warn(postData, 'postData');

      this.$_API.get(`${this.$_API_ROUTES.app.cropAssignMap}?per-page=1000`, {
        headers: {...this.$_Auth.getAuthHeader()},
      }).then(({data}) => {
        if (data.items) {
          this.setProtectionsMapper(data.items);
        }
      });
    },
    initMetaCultures() {
      this.$_API.get(this.$_API_ROUTES.app.cultures, {
        headers: {...this.$_Auth.getAuthHeader()},
      }).then(({data}) => {
        this.setCrop(data.items);
      });
    },
  },
};
