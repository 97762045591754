import Cookies from 'vue-cookie';

const CoockieDriver = {
  authTokenId: '',
  authPermissionId: 'permission_token',
  authStorage: Cookies,

  setAuthTokenKey(key) {
    this.authTokenId = key;
  },
  setAuthToken(token, rememberFlag) {
    const expires = rememberFlag ? '30D' : '15m';
    this.authStorage.set(this.authTokenId, token, {expires: expires});
  },
  clearAuthToken() {
    this.authStorage.delete(this.authTokenId);
  },
  getAuthToken() {
    return this.authStorage.get(this.authTokenId);
  },
  setAuthPermission(permissionToken) {
    this.authStorage.setItem(this.authPermissionId, permissionToken);
  },
  getAuthPermissionToken() {
    return this.authStorage.getItem(this.authPermissionId);
  },
};

export default CoockieDriver;
