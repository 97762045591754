import axios from 'axios';

/**
 * Service helper
 */
import ApiHelper from './ApiHelper';

/**
 * Axios configs
 */
import ApiConfig from './ApiConfig';

/**
 * API routes pair
 */
import ApiRoutes from './ApiRoutes';

const ApiService = {
  install(Vue) {
    Vue.prototype.$_API = axios.create(ApiConfig);
    Vue.prototype.$_API_HELPER = ApiHelper;
    Vue.prototype.$_API_ROUTES = ApiRoutes;

    Vue.prototype.$_API.interceptors.response.use(
      (response) => {
        // Uncomment for developing
        if (ENV.dev) {
          //   console.warn(`${response.status} - ${response.config.url}`);
        }
        return Promise.resolve(response);
      },
      (error) => {
        // Canceled response handling
        if (axios.isCancel(error)) {
          console.warn(`Request canceled:`, `${error.message}.`);
          return Promise.reject(new Error(ApiHelper.apiStatuses.canceled));
        }

        if (error.response !== undefined) {
          ApiHelper.statusErrorHandling(error.response.status, error.response.data.message);
        }

        console.error(error);
        return Promise.reject(error);
      }
    );
  },
};

export default ApiService;
