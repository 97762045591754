export default {
  namespaced: true,
  state: {
    companyData: {},
    currency: {},
    crop: {},
    pest: {},
    protectionMap: {},
    protection: {},
  },
  getters: {
    currency: (state) => state.currency,
    crop: (state) => state.crop,
    pest: (state) => state.pest,
    protectionMap: (state) => state.protectionMap,
    protection: (state) => state.protection,
    companyData: (state) => state.companyData,
  },
  mutations: {
    setCompanyData(state, companyData) {
      state.companyData = {};

      _.forEach(companyData, (keyData, key) => {
        state.companyData[key] = keyData;
      });
    },
    setPests(state, pest) {
      state.pest = {};

      _.forEach(pest, (keyData) => {
        state.pest[keyData.id] = keyData;
      });
    },
    setProtections(state, protection) {
      state.protection = {};

      _.forEach(protection, (keyData, key) => {
        state.protection[keyData.id] = keyData;
      });
    },
    setProtectionsMapper(state, list) {
      state.protectionMap = {};

      _.forEach(list, (item) => {
        if (state.protectionMap[item['crop_id']] === undefined) {
          state.protectionMap[item['crop_id']] = {
            pest: [],
            protection: [],
          };
        }

        if (state.protectionMap[item['crop_id']]['pest'].indexOf(item.pest_id) === -1) {
          state.protectionMap[item['crop_id']]['pest'].push(item.pest_id);
        }

        if (state.protectionMap[item['crop_id']]['protection'].indexOf(item.protect_id) === -1) {
          state.protectionMap[item['crop_id']]['protection'].push(item.protect_id);
        }
      });
    },
    setCurrency(state, currency) {
      const currencyResulted = {};

      _.forIn(currency, (currencyData) => {
        currencyResulted[currencyData.id] = {
          id: currencyData.id,
          name: currencyData.name.length > 10 ? `${currencyData.name.slice(0, 8)}...` : currencyData.name,
          title: currencyData.name,
        };
      });

      state.currency = _.cloneDeep(currencyResulted);
    },
    setCrop(state, crop) {
      const cropsResulted = {};

      _.forIn(crop, (cropData) => {
        cropsResulted[cropData.id] = {
          id: cropData.id,
          name: cropData.name.length > 10 ? `${cropData.name.slice(0, 8)}...` : cropData.name,
          title: cropData.name,
        };
      });

      state.crop = _.cloneDeep(cropsResulted);
    },
  },
  actions: {},
};
