// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js', {scope: '/'}).then(() => {
//     console.warn('registered');
//   }, err => console.warn(err));
// }

/* eslint-disable no-console */
import {register} from 'register-service-worker';

// if (process.env.NODE_ENV === 'production') {
  register(`/service-worker.js`, {
    ready() {
      console.warn('App is being served from cache by a service worker.',);
    },
    registered(registration) {
      console.warn(`Service worker has been registered. Scope: ${registration.scope}`);
    },
    cached(registration) {
      console.warn('Content has been cached for offline use.');
    },
    updatefound() {
      console.warn('New content is downloading.');
    },
    updated(registration) {
      console.warn('New content is available; please refresh.');
    },
    offline() {
      console.warn('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
// }

