import AuthRoutes from './routes/AuthRoutes';
import ErrorRoutes from './routes/ErrorRoutes';
import PageRoutes from './routes/PageRoutes';
import ActionRoutes from './routes/ActionRoutes';
import UserRoutes from './routes/UserRoutes';
import CompanyRoutes from './routes/CompanyRoutes';
import GreenhouseRoutes from './routes/GreenhouseRoutes';
import AppSettingsRoutes from './routes/AppSettingsRoutes';
import BlockRoutes from './routes/BlockRoutes';
import HouseRoutes from './routes/HouseRoutes';
import CellRoutes from './routes/CellRoutes';
import RecommendationRoutes from './routes/RecommendationRoutes';

const ApiRoutes = {
  auth: AuthRoutes,
  actions: ActionRoutes,
  user: UserRoutes,
  pages: PageRoutes,
  errors: ErrorRoutes,
  company: CompanyRoutes,
  greenhouse: GreenhouseRoutes,
  block: BlockRoutes,
  house: HouseRoutes,
  cell: CellRoutes,
  app: AppSettingsRoutes,
  recommendation: RecommendationRoutes,
};

export default ApiRoutes;
