import en from './dictionary/en';
import ru from './dictionary/ru';

const DICTIONARY_LIST = {
  ru: ru,
  en: en,
};
const DEFAULT_LOCATION = 'ru';

const LangService = {
  getDictionary() {
    return DICTIONARY_LIST[DEFAULT_LOCATION];
  },
};

export default LangService;
