/**
 * Documentation link: https://github.com/axios/axios#request-config
 *
 * @type Object
 */
const ApiConfig = {
  // baseURL: 'http://agrowebapp.server.fvds.ru/',
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter, false),
  // timeout: 1000,
  // headers: {
  //   Authorization: `${token}`,
  //   X-Custom-Header: 'foobar',
  // },}
  // },
};

export default ApiConfig;
