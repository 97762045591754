const ALL_REQUIRED = 'Все поля обязательны для заполнения';
const PROBLEM_LENGTH = 'После `Проблема` должно быть не менее 3 символов';
const MESSAGE_LENGTH = 'После `Сообщение` должно быть не менее 5 символов';

export default {
  methods: {
    validateSendHelpParams(from, title, message) {
      if (from.length === 0 || message.length === 0 || title.length === 0) {
        this.$message.errorMessage(ALL_REQUIRED);
        return false;
      }

      if (title.length < 4) {
        this.$message.errorMessage(PROBLEM_LENGTH);
        return false;
      }

      if (message.length < 5) {
        this.$message.errorMessage(MESSAGE_LENGTH);
        return false;
      }

      return true;
    },
    async sendHelpAsync(from, title, message) {
      if (!this.validateSendHelpParams(from, title, message)) {
        return Promise.resolve(false);
      }

      const route = this.$_API_ROUTES.app.sendHelp;

      const postData = {
        from: from,
        title: title,
        message: message,
      };

      return this.$_API.post(route, postData, {
        headers: this.$_Auth.getAuthHeader(),
      }).then(({data}) => {
        this.$errors.parseErrors(data.errors);

        if (data.success) {
          this.$message.successMessage('Письмо отправлено');
        }

        return Promise.resolve(data.success);
      }).catch((error) => {
        console.error(error);
        this.$message.errorMessage('Возникла ошибка отправки письма');
        throw new Error(error);
      });
    },
  },
};
