export default {
  namespaced: true,
  state: {
    pageBlockId: 0,
    pageHouseId: 0,
    pageCellId: 0,
    pageRowCellId: 0,
    editorCellsData: {},
    greenhouseData: {},
    profileSlide: 'pests',
  },
  getters: {
    pageBlockId: (state) => state.pageBlockId,
    pageHouseId: (state) => state.pageHouseId,
    pageCellId: (state) => state.pageCellId,
    pageRowCellId: (state) => state.pageRowCellId,
    editorCellsData: (state) => state.editorCellsData,
    greenhouseData: (state) => state.greenhouseData,
    profileSlide: (state) => state.profileSlide,
    profileSlideTitle: (state) => {
      switch (state.profileSlide) {
        case 'pests':
          return 'Вредители';
        case 'name':
          return 'ФИО';
        case 'email':
          return 'Email';
        case 'pass':
          return 'Password';
        case 'info':
          return 'Инструкции';
        case 'help':
          return 'Помощь';
        default:
          return 'Профиль';
      }
    },
  },
  mutations: {
    setProfileSlide(state, profileSlide) {
      state.profileSlide = profileSlide;
    },
    setPageBlockId(state, pageBlockId) {
      state.pageBlockId = pageBlockId;
    },
    setPageHouseId(state, pageHouseId) {
      state.pageHouseId = pageHouseId;
    },
    setPageCellId(state, pageCellId) {
      state.pageCellId = pageCellId;
    },
    setPageRowCellId(state, pageRowCellId) {
      state.pageRowCellId = pageRowCellId;
    },
    setGreenhouseData(state, greenhouseData) {
      state.greenhouseData = {};

      _.forEach(greenhouseData, (keyData, key) => {
        /*
         * TODO: add adapter or rewrite it to importing fields from the Configs.
         *       Simple way: change: A-key on B-key in the hole system
         */
        if (key === 'userGreenhouseBlocks') {
          key = 'greenhouseBlocks';
        }

        if (key === 'userGreenhouseHouses') {
          key = 'greenhouseHouses';
        }

        state.greenhouseData[key] = keyData;
      });
    },
    setEditorCellsData(state, editorCellsData) {
      state.editorCellsData = {};

      _.forEach(editorCellsData, (keyData, key) => {
        state.editorCellsData[key] = keyData;
      });
    },
  },
  actions: {},
};
