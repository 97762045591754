const StorePage = {
  namespaced: true,
  state: {
    page: 'greenhouses',
    pageView: 'loader',
    previousPageView: 'greenhouseView',
  },
  getters: {
    page: (state) => state.page,
    pageView: (state) => state.pageView,
    previousPageView: (state) => state.previousPageView,
  },
  mutations: {
    setPage(state, page) {
      if (typeof page === 'object') {
        state.previousPageView = state.page;
        state.page = page.page;
        return;
      }
      state.page = page;
    },
    setPageView(state, pageView) {
      state.previousPageView = state.pageView;
      // window.location.hash = pageView;

      // eslint-disable-next-line no-console
      // console.warn(pageView, 'setPageView');
      state.pageView = pageView;
    },
    setPageViewDeep(state, params = {}) {
      let browserLocation = _.clone(params.pageView);

      // console.warn(params, 'setPageViewDeep params');
      // SET BROWSER
      switch (params.pageView) {
        case 'administrator':
        case 'sadministrator':
          browserLocation = browserLocation + '_' + params['administratorId'];
          break;
        case 'administratorsEdit':
        case 'sadministratorsEdit':
          browserLocation = browserLocation + '_' + params['administratorId'];
          break;
        case 'greenhousesEdit':
          browserLocation = browserLocation + '_' + params['greenhouseEditorId'];
          break;
        case 'company':
          browserLocation = browserLocation + '_' + params['companyEditorId'];
          break;
        case 'employeesAdd':
          browserLocation = browserLocation + '_' + params['companyId'];
          break;
        case 'employee':
          browserLocation = browserLocation + '_' + params['employeeEditorId'];
          break;
        case 'employeesEdit':
          browserLocation = browserLocation + '_' + params['employeeEditorId'];
          break;
        case 'settingsEdit':
          browserLocation = browserLocation + '_' + params['companyEditorId'];
          break;

          // STRUCTURE PAGES
        case 'administratorsAdd':
          browserLocation = browserLocation + '_' + params['administratorsAddStateType'] +
                            '_' + params['administratorsAddStateCompanyId'];
          break;
        case 'greenhousesView':
          browserLocation = browserLocation + '_' + params['greenhouseViewId'];
          break;
        case 'blockView':
          browserLocation = browserLocation + '_' + params['greenhouseViewId'] + '_' + params['blockViewId'];
          break;
        case 'houseView':
          browserLocation = browserLocation + '_' + params['greenhouseViewId'] + '_' + params['blockViewId'] +
                            '_' + params['houseViewId'];
          break;
      }

      window.location.hash = browserLocation;

      // SET SYSTEM
      state.previousPageView = state.pageView;
      state.pageView = params.pageView;
    },
  },
  actions: {
    goToPreviousPage() {
      window.history.back();
    },
  },
};

export default StorePage;
