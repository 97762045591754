<template>
    <div :style="styleContainer" :class="classesContainer">
        <div :style="styleBlock" :class="classesBlock">
            <span v-if="fixedValue > 33">{{ fixedValue }}%</span>
        </div>
        <div v-if="fixedValue < 34" class="progress__less-value">
            {{ fixedValue }}%
        </div>
    </div>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 200,
    },
    value: [Number, String],
  },
  computed: {
    fixedValue() {
      let fixedValue = 0;

      switch (typeof this.value) {
        case 'number':
          fixedValue = this.value.toFixed(0);
          break;
        case 'string':
          fixedValue = parseInt(this.value).toFixed(0);
          break;
      }

      return parseInt(fixedValue);
    },
    colorType() {
      let colorType = 3;
      const percentValueInt = this.fixedValue;

      switch (true) {
        case (percentValueInt > 74):
          colorType = 0;
          break;
        case (percentValueInt > 50 && percentValueInt < 75):
          colorType = 1;
          break;
        case (percentValueInt > 25 && percentValueInt < 51):
          colorType = 2;
          break;
        case (percentValueInt < 26):
          colorType = 3;
          break;
      }

      return colorType;
    },
    styleContainer() {
      return {
        width: `${this.width}px`,
      };
    },
    classesContainer() {
      const classesContainer = [
        'progress__container'
      ];

      switch (this.colorType) {
        case 0:
          classesContainer.push(' -lvl-0');
          break;
        case 1:
          classesContainer.push(' -lvl-1');
          break;
        case 2:
          classesContainer.push(' -lvl-2');
          break;
        case 3:
          classesContainer.push(' -lvl-3');
          break;
      }

      return classesContainer;
    },
    styleBlock() {
      const width = this.fixedValue < 0 ? 0
                                        : this.fixedValue > 100 ? 100 : this.fixedValue;

      return {
        'width': `${width}%`,
        'min-width': `${width}%`,
      };
    },
    classesBlock() {
      const classesBlock = [
        'progress__block'
      ];

      switch (this.colorType) {
        case 0:
          classesBlock.push(' -lvl-0');
          break;
        case 1:
          classesBlock.push(' -lvl-1');
          break;
        case 2:
          classesBlock.push(' -lvl-2');
          break;
        case 3:
          classesBlock.push(' -lvl-3');
          break;
      }

      return classesBlock;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress__container {
  border: 1px solid #4FAD64;
  box-sizing: border-box;
  height: 20px;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  background-color: transparent;

  &.-lvl-0 {
    border: 1px solid #4FAD64;
  }

  &.-lvl-1 {
    border: 1px solid #C5B100;
  }

  &.-lvl-2 {
    border: 1px solid #F36701;
  }

  &.-lvl-3 {
    border: 1px solid #E32929;
  }
}

.progress__less-value {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -4px;
}

.progress__block {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.-lvl-0 {
    background-color: rgba(144, 216, 160, 0.2);
  }

  &.-lvl-1 {
    background-color: rgba(193, 174, 0, 0.2);
  }

  &.-lvl-2 {
    background-color: rgba(243, 103, 1, 0.2);
  }

  &.-lvl-3 {
    background-color: rgba(227, 41, 41, 0.2);
  }
}
</style>
