import { render, staticRenderFns } from "./ThripsTobacco.vue?vue&type=template&id=67bd9dce&scoped=true&"
import script from "./ThripsTobacco.vue?vue&type=script&lang=js&"
export * from "./ThripsTobacco.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67bd9dce",
  null
  
)

export default component.exports