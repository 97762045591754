export default {
  data() {
    return {
      frontier: 0,
      width: 0,
    };
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
  computed: {
    isMobile() {
      const frontier = this.frontier > 0 ? this.frontier : 568;
      return this.width < frontier;
    },
    isWideMobile() {
      const frontier = this.frontier > 0 ? this.frontier : 640;
      return this.width < frontier;
    },
  },
  methods: {
    updateWidth() {
      this.$set(this, 'width', window.innerWidth);
    },
  },
};
