export default {
  login: '/login/',
  registration: '/registration/',
  renew: '/renew/',
  restore: '/restore/',
  admin: '/admin/',
  access: '/access/',
  pwa: '/offline/',
  agronom: '/agronom/',
  superAdmin: '/superAdmin/',
  employee: '/employee/',
};
