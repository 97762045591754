import AuthConfig from './AuthConfig';

const AuthHelper = {

  authPermissionId: null,
  authPermissionTokenId: null,

  authStorageDriver: AuthConfig.getDefaultStorageDriver(),

  setAuthStorage(authStorageDriverName) {
    this.authStorageDriver = AuthConfig.getAuthDriver(authStorageDriverName);
  },
  setAuthToken(token, rememberFlag) {
    // console.warn('setAuthToken');
    this.authStorageDriver.setAuthToken(token, rememberFlag);
  },
  setAuthPermission(permissionToken, userId) {
    // console.warn('setAuthPermission');

    this.setAuthPermissionId(userId);
    this.setAuthPermissionTokenId(permissionToken);
  },
  getAuthPermissionToken() {
    return {
      id: this.getAuthPermissionId(),
      token: this.getAuthPermissionTokenId(),
    };
  },
  setAuthPermissionId(id) {
    this.authPermissionId = id;
  },
  setAuthPermissionTokenId(id) {
    this.authPermissionTokenId = id;
  },
  getAuthPermissionId() {
    return this.authPermissionId;
  },
  getAuthPermissionTokenId() {
    return this.authPermissionTokenId;
  },
  clearAuthToken() {
    this.authStorageDriver.clearAuthToken();
  },
  getAuthToken() {
    return this.authStorageDriver.getAuthToken();
  },
  checkUserPermission() {
    if (this.getAuthPermissionId() === null) {
      return;
    }

    if (this.getAuthPermissionTokenId() === null) {
      window.location.reload();
    }

    if (this.getAuthPermissionTokenId() !== this.getAuthToken()) {
      window.location.reload();
    }
  },
  getAuthHeader() {
    this.checkUserPermission();

    return {
      Authorization: `Bearer ${this.getAuthToken()}`,
    };
  },
};

export default AuthHelper;
