import ApiRoutes from './ApiRoutes';
import ApiAuth from '../auth/AuthHelper';
import axios from 'axios';
import alertify from 'alertifyjs';
import ApiConfig from './ApiConfig';

const ApiHelper = {
  apiStatuses: {
    canceled: 'Canceled',
  },
  statusErrorMessages: {
    419: `Страница устарела. После перезагрузки приложение будет работать в штатном режиме.`,
    unknown: 'Возникла необработанная ошибка веб-сервера',
    default: 'Произошла ошибка.',
  },

  /**
   *  Methods list
   */
  isCanceled(error) {
    return _.has(error, 'message') && error.message === this.apiStatuses.canceled;
  },
  getStatusErrorMessage(errorStatus) {
    return _.has(this.statusErrorMessages, errorStatus) ? this.statusErrorMessages[errorStatus]
                                                        : this.statusErrorMessages.default;
  },
  statusErrorHandling(errorStatus, errorMessage) {
    if (errorMessage === undefined) {
      errorMessage = this.statusErrorMessages.unknown;
    }

    console.warn(`Error status: ${errorStatus}. Message: ${errorMessage}`);

    // NOT HANDLED SERVER ERROR
    if (errorStatus === 500) {
      alertify.error(errorMessage);
    }

    // CSRF-token missmatch
    if (errorStatus === 419) {
      this.reloadPage();
    }

    // NOT AUTHORIZE
    if (errorStatus === 401) {
      this.logout();
    }

    // NOT AUTHORIZE
    if (errorStatus === 403) {
      this.logout();
    }
  },
  reloadPage() {
    window.location.reload();
  },
  logout() {
    const API = axios.create(ApiConfig);

    API.post(ApiRoutes.auth.logout, {}, {
      headers: {...ApiAuth.getAuthHeader()},
    }).then(({data}) => {
      if (data.success) {
        ApiAuth.clearAuthToken();
        this.redirectToLogin();
      }
    });
  },
  redirectToError404() {
    this.redirectTo(ApiRoutes.errors['404']);
  },
  redirectToLogin() {
    this.redirectTo(ApiRoutes.pages.login);
  },
  redirectToAccess() {
    this.redirectTo(ApiRoutes.pages.access);
  },
  redirectToPWA() {
    this.redirectTo(ApiRoutes.pages.pwa);
  },
  redirectToAdmin() {
    this.redirectTo(ApiRoutes.pages.admin);
  },
  redirectToAgronom() {
    this.redirectTo(ApiRoutes.pages.agronom);
  },
  redirectTo(route) {
    window.location.href = route;
  },
};

export default ApiHelper;
