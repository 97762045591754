export default {
  cultures: '/api/crop/',
  currency: '/api/currency/',
  pests: '/api/pest/',
  protections: '/api/protection/',
  cropAssignMap: '/api/crop-assign/',
  pwaStructure: '/api/pwa/get-structure/',
  albums: '/api/photo-album/',
  albumPhotos: '/api/photo-album/get-photos/',
  sendHelp: '/api/email/send/',
  templatePlan: '/getdoc/?file=plan_default.xlsx',
  templateInvoice: '/getdoc/?file=naklad_default.xls',
};
