/* eslint-disable */
import '@babel/polyfill';

// TODO: move to the ApiConfigs
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 *  Set rootApp variable
 * */
window.root_element = document.getElementById('app');

/**
 * Set ENV params
 * */
window.ENV = {
  prod: true,
  dev: false,
}

if ('development' === process.env.NODE_ENV) {
  window.ENV = {
    prod: false,
    dev: true,
  }
}

