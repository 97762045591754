export const ROLE_SYSTEM_ROOT = 'root';
export const ROLE_SYSTEM_SUPER_ADMIN = 'superadmin';

export const ROLE_COMPANY_ADMINISTRATOR = 'administrator';
export const ROLE_COMPANY_SUPER_ADMINISTRATOR = 'super-administrator';
export const ROLE_COMPANY_FINANCIER = 'financier';

export const ROLE_WORKER_AGRONOMIST = 'agronomist';
export const ROLE_WORKER_MONITOR = 'monitor';

export const LIST_ROLES_COMPANY = [
  ROLE_COMPANY_ADMINISTRATOR,
  ROLE_COMPANY_SUPER_ADMINISTRATOR,
  ROLE_COMPANY_FINANCIER
];

export const LIST_ROLES_WITH_FULL_OBJECT_ACCESS = [
  ROLE_SYSTEM_ROOT,
  ROLE_SYSTEM_SUPER_ADMIN,
  ROLE_COMPANY_SUPER_ADMINISTRATOR
];

export const LIST_ROLES_CAN_DELEGATE_COMPANY_SUPER_ADMIN = [
  ROLE_SYSTEM_ROOT,
  ROLE_SYSTEM_SUPER_ADMIN,
  ROLE_COMPANY_SUPER_ADMINISTRATOR
];

export default {
  0: 'Неопределён',

  [ROLE_SYSTEM_ROOT]: 'Суперадминистратор',
  [ROLE_SYSTEM_SUPER_ADMIN]: 'Технолог',

  [ROLE_COMPANY_ADMINISTRATOR]: 'Главный агроном',
  [ROLE_COMPANY_SUPER_ADMINISTRATOR]: 'Администратор',
  [ROLE_COMPANY_FINANCIER]: 'Финансист',

  [ROLE_WORKER_AGRONOMIST]: 'Агроном',
  [ROLE_WORKER_MONITOR]: 'Мониторщик',
};
