const LocalStorageDriver = {
  authTokenId: '',
  authStorage: window.localStorage,

  setAuthTokenKey(key) {
    this.authTokenId = key;
  },
  setAuthToken(token, rememberFlag) {
    this.authStorage.setItem(this.authTokenId, token);
  },
  clearAuthToken() {
    this.authStorage.removeItem(this.authTokenId);
  },
  getAuthToken() {
    return this.authStorage.getItem(this.authTokenId);
  },
};

export default LocalStorageDriver;
