export default {
  block: '/api/greenhouse-block/',

  /**
   * ACTIONS
   */
  doBlock: '/api/greenhouse-block/block/',
  unblock: '/api/greenhouse-block/unblock/',

  /*
   * REPORT
   */
  uploadTransportReport: '/api/protection/set-transport-report/',
  transportReports: '/api/transport-report/',
  finReport: '/api/greenhouse-block/get-financial-report/',

  /**
   * PLAN
   */
  uploadPlan: '/api/protection/set-plan/',
  plans: '/api/landing-plan/',
  planBlock: '/api/landing-plan/get/',
  planBlockResult: '/api/landing-plan-result/',
  planBlockResultSave: '/api/landing-plan-result/save/',

  /**
   * STATISTIC
   */
  statInit: '/api/statistic/init/',
  statPest: '/api/statistic/pests/',
  statProtection: '/api/statistic/protections/',
  statCost: '/api/statistic/costs/',
};
