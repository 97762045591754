const COLOR_RATE_LOW = '#4FAD64';
const COLOR_RATE_MIDDLE = '#C5B100';
const COLOR_RATE_HIGH = '#F36701';
const COLOR_RATE_CRITICAL = '#E32929';

export default {
  data() {
    return {
      stateSelectorCounter: [0, 1, 2, 3],
    };
  },
  methods: {
    setItemRate(state, itemId, field, rate) {
      this.$set(this[state][itemId], field, rate);
    },
    styleSelectorItem(state, itemId, field, rate) {
      const styleSelectorItem = {};

      let itemColor = '';
      switch (rate) {
        case 0:
          itemColor = COLOR_RATE_LOW;
          break;
        case 1:
          itemColor = COLOR_RATE_MIDDLE;
          break;
        case 2:
          itemColor = COLOR_RATE_HIGH;
          break;
        case 3:
          itemColor = COLOR_RATE_CRITICAL;
          break;
      }

      styleSelectorItem.color = itemColor;

      if (state[itemId][field] === rate) {
        styleSelectorItem.boxShadow = `0 0 4px 0 ${itemColor}`;
      }

      return styleSelectorItem;
    },
  },
};
