import LocalStorageDriver from './storageDrivers/LocalStorageDriver';
import CookiesDriver from './storageDrivers/CookiesDriver';

const AuthConfig = {
  authTokenKey: 'agrosso',
  defaultAuthDriver: 'localStorage',
  authStorageDrivers: {
    localStorage: LocalStorageDriver,
    cookie: CookiesDriver,
  },

  // Methods
  getAuthDriver(authStorageDriverName) {
    if (!_.has(this.authStorageDrivers, authStorageDriverName)) {
      throw Error(`Trying to set not defined auth storage driver: ${authStorageDriverName}.`);
    }

    const authStorageDriver = this.authStorageDrivers[authStorageDriverName];
    authStorageDriver.setAuthTokenKey(this.authTokenKey);

    return authStorageDriver;
  },
  getDefaultStorageDriver() {
    return this.getAuthDriver(this.defaultAuthDriver);
  },
};

export default AuthConfig;
