<template>
    <transition name="fade" mode="out-in">
        <keep-alive>
            <component
                :is="selectedComponent"
                v-if="typePest !== null"
                :width="width"
                :height="height"
                :color="color"
            />
        </keep-alive>
    </transition>
</template>

<script>
import Whitefly from './Whitefly.vue';
import Mite from './Mite.vue';
import GreenhouseWhitefly from './GreenhouseWhitefly.vue';
import WesternFlowerThrips from './WesternFlowerThrips.vue';
import ThripsTobacco from './ThripsTobacco.vue';
import Miners from './Miners.vue';
import Caterpillar from './Caterpillar.vue';
import AphidPeach from './AphidPeach.vue';
import CottonAphid from './CottonAphid.vue';
import CucumberMosquito from './CucumberMosquito.vue';
import TutAbsolute from './TutAbsolute.vue';
import CarmineTick from './CarmineTick.vue';
import RustyTomatoMite from './RustyTomatoMite.vue';
import SpiderMite from './SpiderMite.vue';
import Sciarides from './Sciarides.vue';

export default {
  name: 'PestIcon',
  props: {
    type: {
      type: [Array, Object, Number],
      required: true,
    },
    width: {
      type: [String, Number],
      required: false,
      default: '25',
    },
    height: {
      type: [String, Number],
      required: false,
      default: '25',
    },
  },
  data() {
    return {
      componentsPestMap: {
        1: Mite,
        2: GreenhouseWhitefly,
        3: Whitefly,
        4: WesternFlowerThrips,
        5: ThripsTobacco,
        6: Miners,
        7: Caterpillar,
        8: AphidPeach,
        9: CottonAphid,
        10: CucumberMosquito,
        11: TutAbsolute,
        12: SpiderMite,
        13: CarmineTick,
        15: RustyTomatoMite,
        16: Sciarides,
      },
    };
  },
  computed: {
    color() {
      if (this.infectionRate === null) {
        return 'black';
      }

      let color;

      switch (this.infectionRate) {
        default:
          color = 'black';
          break;
        case 0:
        case '0':
          color = '#4FAD64';
          break;
        case 1:
        case '1':
          color = '#C5B100';
          break;
        case 2:
        case '2':
          color = '#F36701';
          break;
        case 3:
        case '3':
          color = '#E32929';
          break;
      }

      return color;
    },
    typePest() {
      let typePest;

      if (typeof this.type === 'number') {
        if (this.type === 0) {
          return typePest;
        }

        return this.type;
      }

      _.forEach(this.type, (item, pestId) => {
        if (pestId === 0) {
          return false;
        }

        typePest = pestId;
      });

      return typePest;
    },
    infectionRate() {
      let infectionRate;

      _.forEach(this.type, (item, pestId) => {
        if (pestId === 0) {
          return false;
        }

        infectionRate = item;
      });

      return infectionRate;
    },
    selectedComponent() {
      return this.componentsPestMap[this.typePest];
    },
  },
};
</script>

<style scoped>

</style>
