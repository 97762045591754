<template>
    <div :class="divFill" @click="$emit('arrow-click')">
        <!--      BACK ARROW-->
        <svg
            v-if="type === 'back'"
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.16627 7.49961L8.85812 0.725363C9.04729 0.558757 9.04729 0.29156 8.85812
                0.124954C8.66895 -0.0416514 8.36556 -0.0416514 8.17638 0.124954L0.14188
                7.20097C-0.0472933 7.36758 -0.0472933 7.63478 0.14188 7.80138L8.17638 14.8743C8.26919
                14.956 8.39411 15 8.51547 15C8.63682 15 8.76175 14.9591 8.85455 14.8743C9.04373
                14.7077 9.04373 14.4405 8.85455 14.2739L1.16627 7.49961Z"
                :fill="svgFill"
            />
        </svg>

        <!--        NEXT ARROW-->
        <svg
            v-if="type === 'next'"
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.83373 7.50039L0.141879 14.2746C-0.0472946 14.4412 -0.0472946 14.7084 0.141879
                14.875C0.331052 15.0417 0.634442 15.0417 0.823615 14.875L8.85812 7.79903C9.04729
                7.63242 9.04729 7.36522 8.85812 7.19862L0.823616 0.125741C0.730814 0.0440104 0.605889
                1.17351e-06 0.484533 1.1629e-06C0.363176 1.15229e-06 0.23825 0.0408661 0.145448
                0.125741C-0.0437247 0.292346 -0.0437247 0.559543 0.145448 0.726149L7.83373 7.50039Z"
                :fill="svgFill"
            />
        </svg>

    </div>
</template>

<script>
export default {
  name: 'PaginationArrow',
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['back', 'next'].indexOf(value) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    divFill() {
      return this.disabled ? 'arrow' : 'arrow active';
    },
    svgFill() {
      return this.disabled ? '#919294' : 'white';
    },
  },
};
</script>

<style scoped>
.arrow {
  height: 25px;
  width: 25px;
  border-radius: 2px;
  border: 1px solid #919294;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: not-allowed;
  background-color: transparent;
}

.active {
  cursor: pointer;
  border: 1px solid #919294;
  background-color: #79B626;
  }
</style>
