/**
 * Language service helper.
 */
import LangHelper from './LangHelper';

const LangService = {
  install(Vue) {
    Vue.prototype.$_Lang = LangHelper;
  },
};

export default LangService;
