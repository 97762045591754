<!-- eslint-disable -->
<template>
    <div>
        <svg
            :width="width" :height="height"
            viewBox="0 0 247 247" fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M153.091 63.973C143.383 62.6633 133.427 62 123.5 62C103.901 62 84.3049 64.6137 66.5815 69.568C42.9409 76.1768 15.3392 89.2418 2.67379 110.372C-4.98655 123.151 4.80511 139.178 19.4587 139.178H227.543C242.215 139.178 251.979 123.136 244.328 110.37C227.637 82.5312 185.546 68.3544 153.091 63.973ZM94.5582 119.885C91.8936 119.885 89.734 122.045 89.734 124.709V129.532H69.4742V78.7736C75.9925 76.9626 82.7803 75.489 89.734 74.3583V124.709C89.734 127.372 91.8936 119.885 94.5582 119.885C97.2228 119.885 99.3824 127.37 99.3824 124.707V73.0279C105.742 72.3005 112.197 71.8576 118.677 71.7087V129.532H99.3824V124.707C99.3824 122.045 97.2228 119.885 94.5582 119.885ZM39.5678 90.5006C45.7205 87.2066 52.5046 84.28 59.8276 81.7642V129.532H39.5678V90.5006ZM128.324 71.7087C134.793 71.8538 141.251 72.291 147.619 73.0184V129.532H128.324V71.7087ZM157.266 74.3564C164.232 75.489 171.018 76.9626 177.525 78.7717V129.532H157.266V74.3564ZM187.174 81.7642C194.497 84.2819 201.281 87.2084 207.432 90.5006V129.532H187.174V81.7642ZM10.9485 115.332C14.9982 108.574 21.4882 102.101 29.9212 96.3349V129.532H19.4587C12.1715 129.532 7.19084 121.6 10.9485 115.332ZM227.543 129.532H217.08V96.3349C225.511 102.101 232.001 108.574 236.053 115.332C239.811 121.602 234.828 129.532 227.543 129.532Z"
                :fill="color"
            />
        </svg>

    </div>
</template>

<script>
export default {
  name: 'RustyTomatoMite',
  props: {
    color: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      required: false,
      default: '25',
    },
    height: {
      type: [String, Number],
      required: false,
      default: '25',
    },
  },
};
</script>

<style scoped>

</style>
