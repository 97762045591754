const StoreGallery = {
  namespaced: true,
  state: {
    level: 0,
    greenhouseId: 0,
    blockId: 0,
    houseId: 0,
    cellId: 0,
    rowId: 0,
    date: '',
    photoId: 0,
    photoData: {},
    albumId: 0,
  },
  getters: {
    level: (state) => state.level,
    date: (state) => state.date,
    greenhouseId: (state) => state.greenhouseId,
    blockId: (state) => state.blockId,
    houseId: (state) => state.houseId,
    cellId: (state) => state.cellId,
    rowId: (state) => state.rowId,
    photoId: (state) => state.photoId,
    albumId: (state) => state.albumId,
    photoData: (state) => state.photoData,
  },
  mutations: {
    setLevel(state, value) {
      state.level = value;
    },
    setDate(state, value) {
      state.date = value;
    },
    setRowId(state, value) {
      state.rowId = value;
    },
    setGreenhouseId(state, value) {
      state.greenhouseId = value;
    },
    setBlockId(state, value) {
      state.blockId = value;
    },
    setHouseId(state, value) {
      state.houseId = value;
    },
    setCellId(state, value) {
      state.cellId = value;
    },
    setPhotoId(state, value) {
      state.photoId = value;
    },
    setAlbumId(state, value) {
      state.albumId = value;
    },
    setPhotoData(state, value) {
      state.photoData = value;
    },
    resetGalleryData(state) {
      state.level = 0;
      state.greenhouseId = 0;
      state.blockId = 0;
      state.houseId = 0;
      state.date = '';
      state.cellId = 0;
      state.albumId = 0;
      state.photoId = 0;
      state.photoData = {};
    },
  },
  actions: {
    // goToPreviousPage({commit, getters}) {
    //   commit('setPageView', getters.previousPageView);
    // },
  },
};

export default StoreGallery;
