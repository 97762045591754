import alertify from 'alertifyjs';

const AppConfigs = {
  // Describe available button types & related configs
  BUTTON_TYPES: {
    grey: {
      transparent: false,
      backgroundColor: '#818181',
      fontColor: '#FFFFFF',
      border: false,
      borderColor: '',
    },
    green: {
      transparent: false,
      backgroundColor: '#79B626',
      fontColor: '#FFFFFF',
      border: false,
      borderColor: '',
    },
    white: {
      transparent: false,
      backgroundColor: '#FFFFFF',
      fontColor: '#79B626',
      border: true,
      borderColor: '#79B626',
    },
    orange: {
      transparent: false,
      backgroundColor: '#FF602E',
      fontColor: '#FFFFFF',
      border: false,
      borderColor: '',
    },
    trans: {
      transparent: true,
      backgroundColor: 'transparent',
      fontColor: '#79B626',
      border: true,
      borderColor: '#79B626',
    },
  },
  FORMATS: {
    LOG: {
      DAY_START: 'YYYY-MM-DD 00:00:00',
      DAY_END: 'YYYY-MM-DD 23:59:59',
    },
  },
};

AppConfigs.install = function (Vue) {
  Vue.prototype.isProduction = window.ENV.prod;
  Vue.prototype.$alert = alertify;
  Vue.prototype.$message = {
    savedPwaItem() {
      alertify.set('notifier', 'position', 'top-right');
      alertify.success('Добавлено в хранилище').dismissOthers();
    },
    saved(top = false) {
      const position = top ? 'top-right' : 'bottom-right';
      alertify.set('notifier', 'position', position);
      alertify.success('Сохранено').dismissOthers();
    },
    error(top = false) {
      const position = top ? 'top-right' : 'bottom-right';
      alertify.set('notifier', 'position', position);
      alertify.error('Возникла ошибка').dismissOthers();
    },
    errorMessage(message, top = false) {
      const position = top ? 'top-right' : 'bottom-right';
      alertify.set('notifier', 'position', position);
      alertify.error(message).dismissOthers();
    },
    successMessage(message, top = false) {
      const position = top ? 'top-right' : 'bottom-right';
      alertify.set('notifier', 'position', position);
      alertify.success(message).dismissOthers();
    },
    message(message, top = false) {
      const position = top ? 'top-right' : 'bottom-right';
      alertify.set('notifier', 'position', position);
      alertify.message(message).dismissOthers();
    },
  };
  Vue.prototype.$errors = {
    parseErrors(errors, dismissOthers = true) {
      if (!errors) {
        return;
      }

      _.forEach(errors, (error, code) => {
        // alertify.error(code + ': ' + error);

        if (dismissOthers) {
          alertify.error(`${error}`).dismissOthers();
        } else {
          alertify.error(`${error}`);
        }
      });
    },
  };
  Vue.prototype.$_CONFIG = this;
};

export default Object.freeze(AppConfigs);
