<template>
    <div class="f-change">
        <div class="f-change__head">
            <div class="f-change__title">
                Доступ к функционалу
            </div>
        </div>

        <div class="f-change__description">
            Доступ распространяется только на доступные объекты теплицы.
            Для изменения перейдите в режим Редактирования.
        </div>

        <div class="f-change__list">
            <template v-for="(ghData, ghId) in list">
                <template v-for="(block, blockIndex) in ghData">
                    <div :key="`${ghId.id}-id-${blockIndex}`" class="f-change__item">
                        <div class="f-change__item-title">
                            {{ ghMap[ghId] }}. {{ block.label }}
                        </div>

                        <template v-for="(blockState, index) in block.data">
                            <div
                                :key="`${ghId}-id-${blockIndex}-${index}-table`"
                                class="f-change__item-row"
                                :class="{'f-change__item-row--sub': !blockState.isMain}"
                            >
                                <div class="f-change__item-key">
                                    {{ blockState.key }}
                                </div>
                                <div class="f-change__item-value">
                                    <v-checkbox
                                        :id="`${ghId}-id-${blockIndex}-${index}-${blockState.key}-check`"
                                        v-model="blockState.value"
                                        :disabled="true"
                                        size="lg"
                                        switch
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export const FUNCTIONALITY_LIST_FULL = {
  0: {
    key: 'Модерирование ролей',
    keyInternal: 'moder_all',
    value: true,
    isMain: true,
    linkTo: '',
    id: 0,
  },
  2: {
    key: 'Главные агрономы',
    keyInternal: 'moder_admin',
    value: true,
    isMain: false,
    linkTo: 'moder_all',
    id: 2,
  },
  3: {
    key: 'Финансисты',
    keyInternal: 'moder_fin',
    value: true,
    isMain: false,
    linkTo: 'moder_all',
    id: 3,
  },
  4: {
    key: 'Агрономы',
    keyInternal: 'moder_agronom',
    value: true,
    isMain: false,
    linkTo: 'moder_all',
    id: 4,
  },
  5: {
    key: 'Мониторщики',
    keyInternal: 'moder_monitor',
    value: true,
    isMain: false,
    linkTo: 'moder_all',
    id: 5,
  },
  6: {
    key: 'Статистика',
    keyInternal: 'stat',
    value: true,
    isMain: true,
    linkTo: '',
    id: 6,
  },
  7: {
    key: 'Финансовая отчетность',
    keyInternal: 'fin',
    value: true,
    isMain: true,
    linkTo: '',
    id: 7,
  },
  8: {
    key: 'План выселения и рекомендации',
    keyInternal: 'plan_rec',
    value: true,
    isMain: true,
    linkTo: '',
    id: 8,
  },
};

export default {
  name: 'FunctionalityViewReadOnly',
  props: {
    objects: {
      type: Object,
      default: () => {},
    },
    ghMap: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    list() {
      const list = {};

      _.forEach(this.objects, object => {
        if (list[object.greenhouse_id] === undefined) {
          list[object.greenhouse_id] = [];
        }

        const functionality = {};
        let countModerationRoles = 0; // if 4 = all

        _.forEach(FUNCTIONALITY_LIST_FULL, (data, id) => {
          id = Number.parseInt(id);
          if (object.functionality[id] === undefined || object.functionality[id].allow !== '1') {
            functionality[id] = this.getFunctionalityItem(id, false);
            return true;
          }

          if ([2,3,4,5].indexOf(id) !== -1) {
            countModerationRoles++;
          }

          functionality[id] = this.getFunctionalityItem(id, true);
        });

        if (countModerationRoles === 4) {
          functionality[0] = this.getFunctionalityItem(0 ,true);
        }

        const data = {
          label: object.all_blocks === '1' ? 'Все блоки' : `Блок ${object.block_id}`,
          data: functionality,
        };

        list[object.greenhouse_id].push(data);
      });

      return list;
    },
  },
  methods: {
    getFunctionalityItem(id, value) {
      return {...FUNCTIONALITY_LIST_FULL[id], value: value};
    },
  },
};
</script>

<style lang="scss" scoped>
.f-change {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  margin: 0 20px 20px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    color: #212121;
  }

  &__description {
    margin-top: 10px;
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 308px;
    margin-right: 80px;
    bottom: 40px;
  }

  &__item-title {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #212121;
  }

  &__item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    margin-top: 10px;

    &--sub {
      margin-left: 30px;
    }
  }

  &__item-key {
    font-family: Circe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
  }

  &__item-value {
    margin-top: 13px;
  }
}
</style>
