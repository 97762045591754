<!-- eslint-disable -->
<template>
    <div>
        <svg
            :width="width" :height="height"
            viewBox="0 0 25 25" fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.8437 13.0027L22.7604 11.3368C22.6862 11.2776 22.595 11.2456 22.5 11.2456H19.595C18.9214
                10.2408 18.2603 9.36216 17.695 8.65417C16.6993 7.40741 15.4155 6.45549 13.9439 5.86371C13.974
                5.8459 14.0036 5.82759 14.0327 5.80873C14.0748 5.82307 14.1197 5.83121 14.1662 5.83121C14.1787
                5.83121 14.1916 5.83081 14.2046 5.82952L17.237 5.55383C18.3203 5.45553 19.3712 5.08487 20.2766
                4.48138C21.1182 3.92077 21.8278 3.16029 22.3299 2.28235L23.2783 0.623489C23.3928 0.424015
                23.3232 0.169511 23.1233 0.0553838C22.9233 -0.059587 22.6687 0.0104276 22.5549
                0.210299L21.6062 1.86916C21.1683 2.6355 20.5483 3.29898 19.8145 3.78833C19.0241
                4.31476 18.107 4.63838 17.1612 4.72418L14.8213 4.9369C14.9361 4.69808 15 4.43782
                15 4.16525C15 4.14774 14.9989 4.13042 14.9984 4.11295C15.7173 3.92817 16.2499 3.27486
                16.2499 2.49929C16.2499 1.58052 15.5024 0.833334 14.5833 0.833334C14.3528 0.833334
                14.1666 1.01991 14.1666 1.2498C14.1666 1.47969 14.3529 1.66626 14.5833 1.66626C15.0429
                1.66626 15.4166 2.03986 15.4166 2.49924C15.4166 2.89164 15.1436 3.22053 14.7777
                3.30841C14.3848 2.58648 13.5121 2.08278 12.5 2.08278C11.4879 2.08278 10.6152 2.58648
                10.2222 3.30841C9.85634 3.22053 9.58331 2.89164 9.58331 2.49924C9.58331 2.03986 9.95706
                1.66626 10.4166 1.66626C10.6471 1.66626 10.8333 1.47969 10.8333 1.2498C10.8333 1.01991
                10.647 0.833334 10.4166 0.833334C9.49748 0.833334 8.74998 1.58052 8.74998 2.49929C8.74998
                3.27486 9.28263 3.92817 10.0015 4.11295C10.001 4.13037 9.99995 4.14769 9.99995
                4.16525C9.99995 4.43782 10.0638 4.69808 10.1786 4.9369L7.83871 4.72418C6.89288
                4.63838 5.9758 4.31476 5.18541 3.78833C4.45166 3.29898 3.83168 2.6355 3.39374
                1.86916L2.44509 0.210299C2.33091 0.010378 2.07591 -0.059587 1.87674 0.0553838C1.67674
                0.169511 1.60714 0.423965 1.72176 0.623489L2.67012 2.28235C3.17219 3.16029 3.88177
                3.92082 4.72344 4.48138C5.62886 5.08487 6.67967 5.45553 7.763 5.55383L10.7955
                5.82952C10.8084 5.83076 10.8213 5.83121 10.8338 5.83121C10.8803 5.83121 10.9252 5.82307
                10.9673 5.80873C10.9965 5.82764 11.0261 5.84595 11.0562 5.86376C9.58435 6.45549 8.30062
                7.40741 7.30501 8.65417C6.73964 9.36216 6.07856 10.2408 5.40497 11.2456H2.50009C2.40508
                11.2456 2.31384 11.2776 2.23967 11.3368L0.156312 13.0027C-0.0232912 13.1464 -0.0524308
                13.4084 0.0913314 13.5883C0.173836 13.6912 0.294664 13.7445 0.417179 13.7445C0.508421
                13.7445 0.600109 13.7145 0.677153 13.6533L2.64629 12.0785H4.86323C3.02034 15.0001
                1.25007 18.7744 1.25007 22.4928C1.25046 22.5482 1.26923 23.8534 2.30674 24.5669C3.15546
                25.1508 4.39174 25.1441 5.99963 24.5385C6.19378 24.454 10.5208 22.5544 12.5 20.25C14.4792
                22.5544 18.8075 24.4544 19.0195 24.5465C19.8229 24.8489 20.5362 25.0001 21.1545
                25.0001C21.7595 25.0001 22.2737 24.8555 22.6933 24.5669C23.7308 23.8535 23.7495
                22.5482 23.7499 22.4908C23.7499 18.7738 21.9797 15 20.1368 12.0786H22.3537L24.3229
                13.6533C24.4 13.7146 24.4916 13.7445 24.5828 13.7445C24.7054 13.7445 24.8262 13.6912
                24.9087 13.5883C25.0524 13.4084 25.0233 13.1464 24.8437 13.0027ZM12.5 2.91581C13.4192
                2.91581 14.1667 3.47642 14.1667 4.16525C14.1667 4.85408 13.4192 5.4147 12.5
                5.4147C11.5808 5.4147 10.8333 4.85408 10.8333 4.16525C10.8333 3.47642 11.5808 2.91581
                12.5 2.91581ZM5.68629 23.7668C4.39129 24.2541 3.38798 24.2953 2.78464 23.8842C2.10549
                23.4219 2.0834 22.4957 2.0834 22.4907C2.0834 17.3034 5.76294 11.9211 7.95631
                9.17395C9.01933 7.84317 10.4442 6.88059 12.0834 6.38468V19.44C10.3834 21.704 5.71504
                23.7541 5.68629 23.7668ZM22.2208 23.8805C21.6191 24.2954 20.6137 24.2562 19.3329
                23.7747C19.2853 23.7542 14.6166 21.704 12.9166 19.44V6.38458C14.5555 6.88044 15.9806
                7.84308 17.0437 9.17395C19.237 11.9211 22.9166 17.3034 22.9166 22.4886C22.9166 22.4977
                22.8929 23.4186 22.2208 23.8805Z"
                :fill="color"
            />
            <path
                d="M7.08331 16.2437C5.93455 16.2437 5 17.1779 5 18.3261C5 19.4743 5.93455 20.4085 7.08331
                20.4085C8.23207 20.4085 9.16662 19.4743 9.16662 18.3261C9.16667 17.1779 8.23207 16.2437
                7.08331 16.2437ZM7.08331 19.5756C6.39414 19.5756 5.83333 19.015 5.83333 18.3261C5.83333
                17.6373 6.39419 17.0767 7.08331 17.0767C7.77244 17.0767 8.33329 17.6373 8.33329
                18.3261C8.33329 19.015 7.77249 19.5756 7.08331 19.5756Z"
                :fill="color"
            />
            <path
                d="M15.833 18.3261C15.833 19.4743 16.7676 20.4085 17.9163 20.4085C19.0651 20.4085 19.9996
                19.4743 19.9996 18.3261C19.9996 17.1778 19.0651 16.2437 17.9163 16.2437C16.7676 16.2437
                15.833 17.1779 15.833 18.3261ZM17.9163 17.0766C18.6055 17.0766 19.1663 17.6372 19.1663
                18.3261C19.1663 19.0149 18.6054 19.5755 17.9163 19.5755C17.2272 19.5755 16.6663 19.0149
                16.6663 18.3261C16.6663 17.6372 17.2271 17.0766 17.9163 17.0766Z"
                :fill="color"
            />
        </svg>

    </div>
</template>

<script>
export default {
  name: 'Whitefly',
  props: {
    color: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      required: false,
      default: '25',
    },
    height: {
      type: [String, Number],
      required: false,
      default: '25',
    },
  },
};
</script>

<style scoped>

</style>
