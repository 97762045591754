export default {
  userData: '/api/user/me/',
  user: '/api/user/',
  adminEdit: '/api/user/edit-admin/',
  addAdministrator: '/api/user/add-company-admin/',
  addEmployee: '/api/user/add-company-employee/',
  editEmployee: '/api/user/employee-edit/',
  block: '/api/user/block/',
  unblock: '/api/user/unblock/',
  rename: '/api/user/change-name/',
  changePass: '/api/user/change-password/',

  access: {
    objectSave: '/api/object-access/assign/',
  },
};
